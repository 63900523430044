* {
  box-sizing: border-box;
}

html,
body {
  max-width: 100%;
  height: 100%;
  overflow-x: hidden;
}

#root {
  text-align: center;
  font-size: calc(10px + 2vmin);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  align-items: center;
}

main {
  width: 100vw;
}

header {
  width: 100%;
}

h1 {
  margin-top: 0;
}

header img {
  margin: 7vw 0 2vw 0;
}

header span {
  margin: 0;
  display: block;
}

.App-link {
  color: #61dafb;
}

.inputBtnContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cstmInput {
  border-radius: 20px;
  width: 80vw;
  max-width: 500px;
  font: inherit;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.1);
  border: 0;
  outline: 0;
  padding: 22px 18px;
  -webkit-appearance: none;
}

.basicdBtn {
  font-size: 1.4rem;
  font-weight: bold;
  font-variant: small-caps;
  border: none;
  cursor: pointer;
  transition: 0.3s;
}

.cstmBtnGo {
  background: rgb(80, 0, 202);
  margin-left: -35px;
  border-radius: 100px;
  padding: 18px;
  color: #fff;
  z-index: 1;
}

.cstmBtnGo:hover {
  transform: scale(1.1);
}

button {
  background: transparent;
  border: 2px solid rgb(80, 0, 202);
  border-radius: 100px;
  font-size: 2rem;
  font-weight: 200;
  color: #4a4a4a;
  transition: 0.3s all;
  margin: 0;
  padding: 10px 20px;
  z-index: 2;
}

button:hover,
button:active {
  color: #fff;
  background: #4a4a4a;
  border-color: transparent;
}

.clipboard-results {
  margin: 1vh 10vw;
}

.read-btn {
  cursor: pointer;
}

.deleteMeText {
  font-size: 10px;
  max-width: 100vw;
  overflow-wrap: break-word;
  padding: 0 11vw;
}

footer {
  width: 100vw;
}

footer svg {
  display: block;
}

footer p {
  width: 100px;
  height: 100px;
  z-index: 1;
  position: absolute;
  bottom: 0;
}

footer p,
footer a {
  color: #3a456b;
  opacity: .9;
  text-decoration: none;
  font-size: 1rem;
  height: 2vh;
  margin-bottom: 10px;
  color: #fff;
  width: 100%;
}
